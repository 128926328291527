
import React, { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { FcCancel, FcApproval } from "react-icons/fc";
import { Audio } from 'react-loader-spinner';
import { BsFillSendFill } from "react-icons/bs";
import { MdPhoneAndroid } from "react-icons/md";
import { MdPayment } from 'react-icons/md';
import { MdAttachEmail } from "react-icons/md";
import { IoMdDownload } from "react-icons/io";
import { CiEdit } from 'react-icons/ci';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../../contexts/Api';
import MyDocument from '../../../../components/MyDocument'; // Adjust the import path as needed
import { getChangedFields,formatCurrency,handleApi, printQuotation, formatMediumDate, deepEqual} from '../../../../utils/utils'; 

const EditPurchaseOrder = ({ originalPO, onClose }) => {
    const [PO, setPO] = useState(originalPO);
    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([]); // All products in the database for autocomplete fields
    const [lineTotals, setLineTotals]=useState([]);
    const [grandTotal, setGrandTotal]=useState(0);
    const [names, setNames] = useState([]);
    const [price, setPrice]=useState(0);
    const [newProductLine, setNewProductLine] = useState(false); // for tracking new product line
    const [hasProductListChanged, setHasProductListChanged] = useState(false); // for tracking new product line
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState({});
    const [customerName, setCustomerName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [phone, setPhone] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isEditable, setIsEditable] = useState(false); // state to manage edit mode
    const { userData, currentColor ,actionCount,setActionCount,companyProfile} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
      // Initialize names based on transactionLines in originalPO
      if (Array.isArray(originalPO.transactionLines)) {
          const initialNames = originalPO.transactionLines.map(line => line.products.name || '');
          setNames(initialNames);
      }
      fetchCustomers();
      fetchProducts();
  }, [originalPO]);

    useEffect(() => {
      // Check if transactionLines is defined and is an array before mapping
      if (Array.isArray(PO.transactionLines)) {
          const newLineTotals = PO.transactionLines.map(line => {
              const price = parseFloat(line.unit_price) || 0;
              const qty = parseFloat(line.quantity) || 1;
              const discount_percentage = parseFloat(line.discount_percentage) || 0;
              const discount_amount = (price * discount_percentage) / 100;
              return (price * qty) - discount_amount;
          });

          setLineTotals(newLineTotals);

          // Calculate grandTotal
          const total = newLineTotals.reduce((total, lineTotal) => total + lineTotal, 0);
          setGrandTotal(total);
      }
  }, [PO.transactionLines]);

    

    const fetchCustomers = async () => {
        await fetchData('customers', userData, setCustomers);
        setIsLoading(false);
    };

    const fetchProducts = async () => {
        if (userData) {
            await fetchData('products', userData, setProducts);
            setIsLoading(false);
        } else {
            console.error('userData or token is undefined');
        }
    };

    const handleTextChange = (event) => {
        const { name, value } = event.target;

        

        setPO(prevPO => {
            const updatedPO = { ...prevPO };

            switch (name) {
                case 'customerName':
                    setCustomerName(value);
                    const selectedCustomer = customers.find((customer) => customer.name === value);
                    if (selectedCustomer) {
                        updatedPO.customer = selectedCustomer; // Add the entire customer object
                        updatedPO.customer_id = selectedCustomer.id;
                        setCustomer(selectedCustomer);
                        setPhone(selectedCustomer.phone_number);
                        setContactPerson(selectedCustomer.contact_person);
                        if (!updatedPO.transactionLines.length && updatedPO.date) {
                            updatedPO.transactionLines.push({ name: '', unit_price: 0, discount_percentage: 0, quantity: 1, });
                        }
                    } else {
                        toast.error('Customer does not exist');
                        setCustomerName('');
                        setPhone('');
                        setContactPerson('');
                    }
                    break;

                case 'date':
                    updatedPO[name] = value;

                    if (updatedPO.customer_id && !updatedPO.transactionLines.length) {
                        updatedPO.transactionLines.push({ name: '', unit_price: 0, discount_percentage: 0, quantity: 1,  });
                    }
                    break;

                default:
                    break;
            }
            return updatedPO;
        });
    };

    const addProductLine = () => {
      setPO(prevPO => {
  
          return {
              ...prevPO,
              transactionLines: [
                  ...(Array.isArray(prevPO.transactionLines) ? prevPO.transactionLines : []), 
                  { id: 0, unit_price: 0, quantity: 1, discount_percentage: 0,  }
              ]
          };
      });
      setHasProductListChanged(!deepEqual(true));
      setNewProductLine(true);
      setIsEditable(true);
  };
  
    const removeProductLine = (index) => { // remove product from PO
      setPO((prevPO) => {
          const updatedProducts = prevPO.transactionLines.filter((_, i) => i !== index);
          return {
              ...prevPO,
              transactionLines: updatedProducts,
          };
      });
  };

  const handleProductChange = (index, field, value) => {
    setPO(prevPO => {
        const updatedTransactionLines = prevPO.transactionLines.map((line, i) => {  
            if (i === index) {
                const updatedTransactionLine = { ...line, [field]: value }; // Correctly reference 'line'

                // Fetch price if product name is updated
                if (field === 'name') {
                  const selectedProduct = products.find(p => p.name === value);
                  if (selectedProduct) {
                      updatedTransactionLine.product_id = selectedProduct.id;
                      updatedTransactionLine.unit_price = selectedProduct.prices.buy_price_new;
                      updatedTransactionLine.discount_percentage = selectedProduct.discount_percentage || 0;

                      // Update names state
                      const updatedNames = [...names];
                      updatedNames[index] = selectedProduct.name; // Ensure the names state is updated
                      setNames(updatedNames);
                  } else {
                      updatedTransactionLine.unit_price = 0;
                  }
              }
                // Recalculate lineTotal if necessary
                const price = parseFloat(updatedTransactionLine.unit_price) || 0;
                const qty = parseFloat(updatedTransactionLine.quantity) || 1;
                const discount_percentage = parseFloat(updatedTransactionLine.discount_percentage) || 0;
                const discount_amount = (price * discount_percentage) / 100;
              //  setLineTotal((price * qty) - discount_amount);

                return updatedTransactionLine;
            }
            return line; // Ensure to return the current line if it's not being updated
        });

        // Calculate the grand total
      //   const grandTotal = updatedTransactionLines.reduce((total, line) => {
      //     return total + (parseFloat(lineTotal) || 0); // Ensure line_total_taxed is treated as a number
      // }, 0);
      

        // Check for duplicate product logic remains the same...
        const isProductDuplicate = updatedTransactionLines.some((prod, i) => prod.name === updatedTransactionLines[index].name && i !== index);

        if (!isProductDuplicate) {
            const lastUpdatedTransactionLine = updatedTransactionLines[updatedTransactionLines.length - 1];
            if (lastUpdatedTransactionLine && lastUpdatedTransactionLine.name) {
                updatedTransactionLines.push({ name: '', unit_price: 0, discount_percentage: 0, quantity: 1});
                setNewProductLine(true);
            }
        } else {
            updatedTransactionLines[index] = { name: '', unit_price: 0, discount_percentage: 0, quantity: 1, };
            toast.error('Product already exists in the purchase order.');
        }

        return { ...prevPO, transactionLines: updatedTransactionLines }; // Set the grand total in PO state
    });
};


    const sendEmailWithAttachment = async (file) => {
        try {
            // Create a FormData object to send the file
            const formData = new FormData();
            formData.append('to', 'client@example.com'); // Replace with the recipient's email address
            formData.append('subject', 'Your PO');
            formData.append('text', 'Please find the attached PO.');
            formData.append('file', file);
    
            // Replace with your email service API endpoint and authentication
            const response = await fetch('https://your-email-service.com/send', {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to send email.');
            }
    
            toast.success('Email sent successfully.');
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email.');
        }
    };

   

    
    const handlePrint = () => {
        const updatedPO = { ...PO, companyProfile };
        printQuotation(updatedPO);
    };

    const handleDownload = async () => {
        // Generate the PDF document
        const blob = await pdf(<MyDocument PO={PO} companyProfile={companyProfile} />).toBlob();

        // Save the PDF file
        saveAs(blob, 'PO.pdf');
    };

    const handleEmail = async () => {
        try {
            // Generate the PDF document
            const blob = await pdf(<MyDocument PO={PO} companyProfile={companyProfile} />).toBlob();
            
            // Create a file object from the blob
            const file = new File([blob], 'PO.pdf', { type: 'application/pdf' });
    
            // Send the email with the attachment
            await sendEmailWithAttachment(file);
        } catch (error) {
            console.error('Error generating PDF:', error);
            toast.error('Failed to generate PDF.');
        }
    };

    const handleApproveBtnClick = () => {
      const updatedPO = { ...PO, state: 'approved' };      // Create the updated PO object
      const apiMethod = 'PUT';
      handleSubmit(null, apiMethod, updatedPO);  // Pass the updated PO
  };
  

    const handleCancelBtnClick = async () => {
      const updatedPO = { ...PO};      // Create the updated PO object
      const apiMethod = 'DELETE';
      const response = await handleSubmit(null, apiMethod,updatedPO);  // Call handleSubmit and wait for the response
      if (response && response.status === 200) {  onClose();}  // If the response is successful, close the modal
  };

  const handleSaveBtnClick = () => {
    const updatedPO = { ...PO};      // Create the updated PO object
     const apiMethod = 'PUT';
    handleSubmit(null, apiMethod, updatedPO);
  };
  
  
  const handleSubmit = async (e, apiMethod,updatedPO) => {
    if (e && e.preventDefault) { e.preventDefault(); } // Prevent default only if e is present

    const updatedTransactionLines = (updatedPO.transactionLines || []).map(({ id,product_id, unit_price, discount_percentage, quantity }) => ({
      id,
      product_id,
      unit_price,
      discount_percentage,
      quantity,
    }));
    
    const cleanedProducts = (updatedTransactionLines || []).filter(product => product.product_id); // Keep only products with product_id
    
    if (apiMethod !== 'DELETE' && cleanedProducts.length === 0) {
      toast.error('PO does not have products. Please add at least one product.');
      return; // Exit the function early if no transaction lines
    }
    
   // if (cleanedPO.totalTaxedPrice) {delete cleanedPO.totalTaxedPrice; }// Remove totalTaxedPrice if it exists
 

    const cleanedPO = {
      id: updatedPO?.id,
      ...(updatedPO?.state !== undefined && updatedPO.state !== originalPO?.state && { state: updatedPO.state }), // Include state if it exists and has changed
      transactionLines: cleanedProducts,
    };
    
   
    const apiEndpoint = 'purchase/orders';
    const parameters = {id:cleanedPO?.id};
    
    console.log('cleaned PO', cleanedPO);
    debugger
    setIsLoading(true);
    
    try {
      const response = await handleApi(apiEndpoint, cleanedPO, userData, apiMethod, parameters);
       if (response.status === 200) {toast.success('PO updated successfully.');
       } else {toast.error('Error saving PO.', response.statusText);}
       return response; // Return the response for checking
    } catch (error) {
        toast.error('Error saving PO: ' + error.message);
        return null; // Return null in case of error
    } finally {
        setActionCount(actionCount + 1);
        setIsLoading(false);
    }
};


 

  const handleCancel = () => {
       // resetForm()
   };

    const handleClose = () => {
        navigate(-1);
    };


    // const total = (PO.transactionLines?.reduce((total, product) => total + product.total, 0) || 0).toFixed(2);
   // const total = grandTotal || 0;
    const shouldShowProductLinesHeader = PO?.date && PO?.customer_id;
    const userRole = userData?.data?.user_roles?.name?.toLowerCase();
    const userDepartment = (userData?.data?.employees?.departments?.name || '').toLowerCase();
    
    const allowedRoles = ['officer', 'admin', 'executive', 'supervisor', 'manager']; // roles allowed to edit 
    const allowedDepartments = ['procurement']; // departments allowed to edit 
    
    const isRoleAllowed = userRole && allowedRoles.includes(userRole);
    const isDepartmentAllowed = userDepartment && allowedDepartments.includes(userDepartment);
    
    // Updated isUserAllowed logic
    const isUserAllowed = (isRoleAllowed && isDepartmentAllowed) || ['admin', 'executive'].includes(userRole);
    const isOfficerAllowed= (isDepartmentAllowed && userRole.toLowerCase()  ==='officer'&& PO?.state.toLowerCase() ==='draft');
  
     console.log('names sir ',names);
      // Calculate grandTotal dynamically
    //   const grandTotal = PO.transactionLines.reduce((total, line) => {
    //     return total + (parseFloat(line.lineTotal) || 0);
    // }, 0);
  
    return (
      <>
        <div className="">
          <div className="flex">
            <h2 className="text-blue-700  text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Purchase Order </h2>
          </div>
          <div>
          {isLoading ? (
            <div className="flex items-center flex-col">
              <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
              <p className="mt-2">Processing data. Please wait...</p>
            </div>
          ) : (
            <>
              {/* meneu icon */}
              <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm: px-4 py-2 flex flex-row justify-end ">
              {(isUserAllowed || isOfficerAllowed) && ( // show to office if order is in draft
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Delete"
                    onClick={handleCancelBtnClick}
                  >
                    <FcCancel size={20} color={currentColor} />
                  </button>
              )}
             
                {PO?.transactionLines && grandTotal > 0 && PO.state ==='draft' && (
                <button
                  className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                  title="Approve"
                  onClick={handleApproveBtnClick}
                >
                  <FcApproval size={20} color={currentColor} />
                </button>
                   )}
                {isUserAllowed && !isEditable &&(
                        
                <button
                  className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                  title="Edit"
                  onClick={addProductLine}
                >
                  <CiEdit size={20} color={currentColor} />
                </button>
              
                )}
                

                {isEditable && (
                  <button
                    className="bg-transparent mt-3 hover:bg-red-400 hover:text-white text-red-700 py-1 px-1 rounded mr-2 transition-all duration-300"
                    title="Submit"
                    onClick={handleSaveBtnClick}
                  >
                    <BsFillSendFill size={20} color="blue" />
                  </button>
                )}
               
              </div>
              {/* end menu icons */}
              {/* Quantity adjustment  preamble info */}
              <div className="mb-2 mx-2 md:mx-1  py-2 md:py-2 bg-gray-50 rounded-3xl"> 
                <div className="w-full sm:w-auto sm:flex-1 px-4 flex flex-col ">
                  <div className="flex flex-wrap justify-between gap-4 mb-4">
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">PO #.</p>
                      <p className="whitespace-nowrap text-slate-500 font-semibold text-xs uppercase">
                        {PO?.reference|| 'NA'}
                      </p>
                    </div>
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Date</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold uppercase">
                        {formatMediumDate(PO?.date) || 'NA'}
                      </p>
                    </div>
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Status</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold uppercase">
                        {PO?.state || 'NA'}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-between gap-4">
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Customer</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold uppercase">
                        {PO?.partners?.name || 'NA'}
                      </p>
                    </div>
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Telephone</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold uppercase">
                          {PO?.partners?.phone_number || 'NA'}
                      </p>
                    </div>
                    <div className="flex-1 min-w-[150px]">
                      <p className="whitespace-nowrap text-blue-500 text-xs">Contact Person</p>
                      <p className="whitespace-nowrap text-slate-500 text-xs font-semibold uppercase">
                          {PO?.partners?.contact_person || 'NA'}
                      </p>
                    </div>
                  </div>  
                </div>
              </div>
              {/* end quantity adjust preamble info */}
        {/* Products lines  */}
        
          <>
            <div className="flex justify-center items-center">
              <p className="whitespace-nowrap mb-1 text-slate-500 text-center">Product Lines</p>
            </div>
            <div className="mx-2 md:mx-1 py-0 md:py-0 bg-blue-500 rounded-3xl">
              <div className="w-full sm:w-auto sm:flex-1 px-4 flex flex-col">
                {/* Table Header */}
                <div className="flex items-center font-semibold mb-2 text-white">
                  <div className="w-1/12 text-center"></div>
                    <div className="w-1/3">Product Name</div>
                      <div className="w-1/6 text-right mr-4 ">Price</div>
                        <div className="w-1/6 mx-2">Quantity</div>
                        <div className="w-1/6 mx-2">Discount</div>
                        <div className="w-1/6 text-right">Total</div>
                        <div className="w-1/9 text-right"></div>
                      </div>

                      <div className="space-y-2">
                      
{/* Ensure `PO` and `PO.products` are defined and `PO.products` is an array */}
{Array.isArray(PO.transactionLines) && PO.transactionLines.length > 0 ? (
  PO.transactionLines.map((product, index) => (
    <div key={index} className="flex items-center mb-2 text-white">
      <div className="w-1/12 text-center font-semibold">{index + 1}</div>
      <input
        type="text"
        value={names[index] || ''} // Bind to names state
        onChange={(e) => {
          const updatedNames = [...names];
          updatedNames[index] = e.target.value; // Update names state
          setNames(updatedNames);
          handleProductChange(index, 'name', e.target.value); // Call existing handler
        }}
        className="px-2 py-1 w-1/3 rounded-lg border font-semibold text-black border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
        list="productList"
        placeholder="Product Name"
        disabled={!isEditable}
      />
      <datalist id="productList">
        {Array.isArray(products) && products.map((p, idx) => (
          <option key={idx} value={p.name} />
        ))}
      </datalist>

      {/* <div className="w-1/6 text-right font-semibold mr-2 ">
        MK {product.unit_price || '0.00'}
      </div> */}

      <input
        type="number"
        value={product.unit_price || 0}
        onChange={(e) => handleProductChange(index, 'unit_price', e.target.value)}
        className="px-4 py-1 w-1/6 mx-2 text-right text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
        placeholder="Unit Price"
        disabled={!isEditable} // Conditionally disable input
      />

      <input
        type="number"
        value={product.quantity || 0}
        onChange={(e) => handleProductChange(index, 'quantity', e.target.value)}
        className="px-4 py-1 w-1/6 text-right text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
        placeholder="Quantity"
        disabled={!isEditable} // Conditionally disable input
      />
      <input
        type="number"
        value={product.discount_percentage            || 0}
        onChange={(e) => handleProductChange(index, 'discount_percentage', e.target.value)}
        className="ml-2 px-4 py-1 w-1/6 text-right text-black font-semibold rounded-lg border border-gray-300 focus:ring focus:ring-opacity-50 focus:ring-blue-300"
        placeholder="Disc"
        disabled={!isEditable} // Conditionally disable input
      />
      <div className="w-1/6 text-right font-semibold">
        MK {lineTotals[index] ? lineTotals[index] : '0.00'}
      </div>



      {/* Remove button */}
      <button onClick={() => removeProductLine(index)} className="ml-2 text-red-500" disabled={!isEditable}  >
        <AiOutlineCloseCircle />
     
      </button>
       {/* end Remove button */}
    </div>
  ))
) : (
  <div className="flex justify-center items-center h-32 bg-white rounded-lg">
    <p className="text-slate-500 text-lg">No products available</p>
  </div>
)}

                        </div>
                        {/* Grand Total */}
                        <div className="flex items-center font-semibold mt-4 text-white">
                          <div className="w-1/12 text-center"></div>
                          <div className="w-1/3"></div>
                          <div className="w-1/6"></div>
                          <div className="w-1/6"></div>
                          <div className="w-1/6 text-right">Total:</div>
                          <div className="w-1/6 text-right font-semibold">
                               {formatCurrency(grandTotal)}
                          </div>
                        </div>
                      </div>
                    </div>
                    {isEditable && (
          
                      <div className="flex justify-end p-2">
                        <button
                          className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                          disabled={!isEditable} // Conditionally disable input
                          onClick={handleSaveBtnClick}
                          title='Submit'
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </>
                
                {/* end products line */}
              </>
            )}
          </div>
        </div>
      <ToastContainer />
    </>
  );
};

export default EditPurchaseOrder;
