
import React, { createContext, useState, useEffect } from 'react';
import AppContext from './AppContext';
import fetchData from '../contexts/Api'; 
import { handleApi} from '../utils/utils'; 


const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
  themeSettings:false,
  isLoggedIn: false,  // Initialize isLoggedIn to false
};

const AppContextProvider = ({children}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState([]);
  const [themeSettings, setThemeSettings] = useState(false);
  const [currentColor, setCurrentColor] = useState('#03C9D7');
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentMode, setCurrentMode] = useState('Light');
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [actionCount, setActionCount] = useState(0);
  const [state, setState] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [component, setComponent] = useState('');
  const [invoices, setInvoices] = useState([]);
  const [products, setProducts] = useState([]);

  const [companyProfile, setCompanyProfile] = useState({});
  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
  };

  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
        localStorage.setItem('userData', JSON.stringify(userData));
        fetchProducts();
    }
    fetchCompanyProfile();
}, [userData]);


  /*useEffect(() => {
    fetchData();
  }, []); // Fetch data only once when the component mounts
  */

  

  const fetchProducts = async () => {
    const apiEndpoint = 'products';
    const apiMethod = 'GET';
    const cleanedData = {};
    const parameters = {};

    try {
      const response = await handleApi(apiEndpoint, cleanedData, userData,apiMethod,parameters);
      if (response.error) {
        console.error(`Error fetching report: ${response.errorMessage}`);
        return;
      }

      const { status, statusText, data } = response;
      if (status === 200) {setProducts(data);
      } else {console.error(`Error ${status}: ${statusText}`);}
    } catch (error) {
      console.error(console.message || 'An unexpected error occurred.');
    }
  };


  const fetchCompanyProfile = async () => {
    const apiEndpoint = 'company_profile';
    const apiMethod = 'GET';
    const cleanedData = {};
    const parameters = {};

    try {
      const response = await handleApi(apiEndpoint, cleanedData, userData,apiMethod,parameters);
      console.log(response)
      if (response.error) {
        console.error(`Error fetching report: ${response.errorMessage}`);
        return;
      }

      const { status, statusText, data } = response;
      if (status === 200) {setCompanyProfile(data);
      } else {console.error(`Error ${status}: ${statusText}`);}
    } catch (error) {
      console.error(console.message || 'An unexpected error occurred.');
    }
  };



  return (
   <AppContext.Provider 
      value={{
        isLoggedIn,setIsLoggedIn,userData,setUserData,
        themeSettings, setThemeSettings,currentColor, 
        setCurrentColor,currentMode,setCurrentMode,activeMenu, setActiveMenu , 
        isClicked, setIsClicked, handleClick, screenSize, setScreenSize,
        initialState,setMode,setColor, invoices,setInvoices, actionCount, setActionCount,
          state, setState,isOpen, setIsOpen, component,setComponent,products,companyProfile,setCompanyProfile
      }}>
      {children}
   </AppContext.Provider>
  )
}
export default AppContextProvider

