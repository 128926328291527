import React, { useState,useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import { handleApi,formatMediumDate,inputDate} from '../../../../utils/utils';
import { paragraphFormatProperty } from "@syncfusion/ej2/documenteditor";

const DeleteEmployee = ({rowData,onClose }) => {
    const { userData,setActionCount,actionCount } = useContext(AppContext);
    const [employee, setEmployee] = useState({});
    const [fileType, setFileType] = useState('');
    const [fileName, setFileName] = useState('');
    const [base64Data, setBase64Data] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const reasons = [
        { value: 'resignation', label: 'Resignation' },
        { value: 'retirement ', label: 'Retirement' },
        { value: 'pursuit of further education', label: 'Pursuit of further education' },
        { value: 'Career change', label: 'Career change' },
        { value: 'Family reasons', label: 'Family reasons' },
        { value: 'Sickness', label: 'Sickness' },
        { value: 'Layoff', label: 'Layoff' },
        { value: 'Termination for cause', label: 'Termination for cause' },
        { value: 'End of contract', label: 'End of contract' },
        { value: 'Death', label: 'Death' },
        { value: 'Disability', label: 'Disability' },
        { value: 'Mutual agreemen', label: 'Mutual agreemen' },
        { value: 'error_correction', label: 'Error Correction' },
    ];

    useEffect(() => {setEmployee(rowData);},[rowData]);
  
    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setEmployee(prevEmployee => {
            let updatedEmployee = { ...prevEmployee, [name]: value };
            if (name === 'separate_reason') {
                if (value.toLowerCase() !== 'error correction') { updatedEmployee.active = 0; } 
                updatedEmployee[name] = value;
            } else { updatedEmployee[name] = value;}
            return updatedEmployee;
        });
    };
    
    const handleSubmit = async (e,) => {
        if (e && e.preventDefault) {e.preventDefault();} // Prevent default only if e is present
        if (!employee.separate_reason) {
            toast.error('Please enter reason for separating');
            return;
        }
    
        if (!employee.separate_date) {
            toast.error('Please enter separation date');
            return;
        }
        let cleanedEmployee={};
        let apiMethod="";
        if (employee.separate_reason.toLowerCase() === 'error correction') {
            apiMethod = 'DELETE';
            cleanedEmployee =  { id: employee.id }; // Only send the ID for deletion
        } else {
            apiMethod = 'PUT';
            cleanedEmployee =  { id: employee.id, active:0}; // Only send the ID for deletion
        }
       
        setIsLoading(true);
        try {
            const response = await handleApi('employees', cleanedEmployee, userData, apiMethod);
            console.log('API Response:', response); // Log the response
            if (response.status === 200) {toast.success('Deleted updated  successfully.');
            }else {toast.error('Error saving Deleted .', response.statusText);}
        } catch (error) {
          toast.error('Error deleting employee: ' + error.message);
        } finally {
          setActionCount(actionCount + 1);
          setIsLoading(false);
        }
    };


    
    
        console.log('parent employee',employee)
        const photoUrl = employee?.photo_url
        ? employee?.photo_url
        : `data:${fileType};base64,${base64Data}`;
        console.log('article photo at this time',photoUrl)

        

    return (
        <div className="">
          <div className="flex justify-between">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">Separate Employee</h2>
                
               
            </div> 
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                {/* purchaseemployee preamble info */}
                <div className="m-2 md:m-1  md:p-2 bg-gray-50 rounded-3xl">
                    <div className="flex flex-wrap "> 
                        
                        <div className="w-full sm:w-auto sm:flex-1 sm:border-b-0 sm:border-r border-gray-200 px-4 py-2">
                        <img
                            className="rounded-full h-24 w-24"
                            src={photoUrl} alt="employee"
                        />
                        </div>
                        <div className="w-full sm:w-auto sm:flex-1  px-4 py-2">
                            <h3 className="whitespace-nowrap uppercase font-bold mb-4" >
                            {employee?.first_name} {employee?.last_name} 
                            </h3>
                            <p className="whitespace-nowrap text-slate-400 text-xs">Department </p>
                            <p className="whitespace-nowrap uppercase font-bold text-xs mb-2"> {employee?.departments?.name}
                            </p>
                            <p className="whitespace-nowrap text-slate-400 text-xs ">Hired Date</p>
                            <p className="whitespace-nowrap uppercase font-bold  text-xs" >
                                {/* {payment.partners.name ?employee.partners.name: ''} */}
                                {formatMediumDate(employee?.hire_date)}
                            </p>
                        </div>
                    </div>
                </div>
                {/* end employee preamble info */}
                
                 {/* separation form */}
                <div className="m-2 md:m-1  p-2 md:p-2 bg-white rounded-3xl">
                <div className="shadow-md rounded px-8 pt-4 mb-2"> 
                        <div className="relative z-0 mb-8  group">
                        <input
                                type="text"
                                id="title"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                name="separate_reason"
                                onChange={(event) => handleTextChange(event)}
                                value={employee?.separate_reason}
                                list="reasonsList" // Link the input to the correct datalist
                            />

                            <datalist id="reasonsList">
                                {reasons.map((reason, index) => (
                                    <option key={index} value={reason.label} />
                                ))}
                            </datalist>
                            <label htmlFor="first_name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Reason for separating
                            </label>
                        </div>
                        <div className="relative z-0 mb-8 group">
                            <input
                                type="date"
                                name="separate_date"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="reasonsList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                value={inputDate(employee?.separate_date)}
                            />
                            <datalist id="reasonsList">
                                                {reasons.map((reason, index) => (
                                                    <option key={index} value={reason.label} />
                                                ))}
                                            </datalist>
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="sepatrate_date"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Date of separation
                            </label>
                        </div>

                    <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
                {/* separation form*/}
      
                <div className="w-full">
                    
                </div>
            </div>
        </div>
    );
};

export default DeleteEmployee;
