import React, { useState,useEffect, useContext } from "react";
import { Audio } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContext from '../../../../contexts/AppContext';
import fetchData from '../../../../contexts/Api'; 
import { employeeTypeProperty } from "@syncfusion/ej2/documenteditor";
import {handleApi,cleanObject} from '../../../../utils/utils'; 

const NewEmployee = ({onClose }) => {
   
    

    const { userData ,actionCount, setActionCount} = useContext(AppContext);
    const [employee, setEmployee] = useState({});
    const [departments, setDepartments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [first_name, setFirst_name] = useState('');
    const [last_name, setLast_name] = useState('');
    const [phone_number, setPhone_number] = useState('');
    const [department_name, setDepartment_name] = useState('');
    const [hire_date, setHire_date] = useState('');
    const [job_title, setJob_title] = useState('');
    const [email, setEmail] = useState('');
    const [displayImg, setDisplayImg] = useState('');
    const [imgType, setImgType] = useState('');

    const initialEmployeeState = {
        

        displayImg:'', 
        imgType:''
    };
    
    const resetForm = () => {
        const updatedEmployee = { 
            first_name: '',
            last_name: '',
            phone_number: '',
            department_id: '',
            hire_date: '',
            job_title: '',
            email:'',
            photo:{},
            
        };
        setEmployee(updatedEmployee);
        setFirst_name('');
        setLast_name('');
        setPhone_number('');
        setDepartment_name('');
        setHire_date('');
        setJob_title('');
        setEmail('');
        setDisplayImg('');
        setImgType('');
       

    };

    useEffect(() => {
        fetchDepartments();}, 
    []);
    
   
    
       
      
 
       
       
    const fetchDepartments = async () => {
        const apiEndpoint = 'departments';
        const apiMethod = 'GET';
        const parameters = {};
        setIsLoading(true);
        const params={};
        try {
          const response = await handleApi(apiEndpoint, params, userData, apiMethod,parameters);
          if (response.error) {
            console.error('Error fection report ', response.errorMessage);// Handle specific API error
            return; // Exit function to avoid further processing
          }
          const { status, statusText } = response;
          if (status === 200) {
            const fetchedDepartments = response.data; // fetched departments
            setDepartments(fetchedDepartments);
          } else { console.error(`Error ${status}: ${statusText}`); } // Handle non-200 responses
         
        } catch (error){ console.error(error.message || 'An unexpected error occurred.');// Handle unexpected errors
        } finally {setIsLoading(false);}
    };

     // Handling input changes
     const handleTextChange = (event) => {
        const { name, value } = event.target;
        setEmployee(prevEmployee => {const updatedEmployee = { ...prevEmployee };
            switch (name) {
                case 'first_name':
                    setFirst_name(value);
                    updatedEmployee[name] = value;
                    break;
                case 'last_name':
                    setLast_name(value);
                    updatedEmployee[name] = value;
                    break;
                case 'department_name':
                    setDepartment_name(value);
                    const selectedDepartment = departments.find((department) => department.name === value);
                    if (selectedDepartment) {updatedEmployee.department_id= selectedDepartment.id;} 
                    break;
                
                case 'phone_number':
                    setPhone_number(value);
                    updatedEmployee[name] = value;
                    break;
                
                case 'hire_date':
                    setHire_date(value);
                    updatedEmployee[name] = value;
                    break;

                case 'job_title':
                    setJob_title(value);
                    updatedEmployee[name] = value;
                    break;

                case 'email':
                    setEmail(value);
                    updatedEmployee[name] = value;
                    break;

                    default:
                    break;
            }
            return updatedEmployee;
        });
    };


    

    const handleImgChange = (event) => {
        const { name, files } = event.target;
        if (name === "img") {convertFile(files);}
    };

    const convertFile = (files) => {
        if (files && files.length > 0 && files[0] instanceof File) {
            const fileRef = files[0];
            const fileName = fileRef.name; // Get the file name
            const fileType = fileRef.type;
            const reader = new FileReader();
            reader.readAsBinaryString(fileRef);
            reader.onload = (ev) => {
                const base64Data = `data:${fileType};bLOase64,${btoa(ev.target.result)}`;
                const  photo= {data:btoa(ev.target.result),contentType:fileType};
                setDisplayImg(btoa(ev.target.result))
                setImgType(fileType)
                setEmployee(prevEmployee => ({...prevEmployee,photo:photo}));
            };
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (!employee.first_name) {toast.error('Please enter first name ');return;  }
        if (!employee.last_name) {toast.error('Please enter last name ');return;  }
        if (!employee.phone_number) {toast.error('Please enter telephone number ');return;  }
        if (!employee.department_id) {toast.error('Please enter Department ');return;  }
        if (!employee.hire_date) {toast.error('Please enter date hired');return;  }
        
        const cleanedEmployee = cleanObject(employee);
        console.log('clean employees',cleanedEmployee)
  
        setIsLoading(true);
        const apiMethod = 'POST';
        const parameters = {};
        //console.log('Cleaned employee sir :', cleanedEmployee, userData, apiMethod);
        try {
            const response = await handleApi('employees', cleanedEmployee, userData, apiMethod,parameters );
            console.log('API Response:', response); // Log the response
    
            if (response.status === 200) {
                toast.success('Employees added  successfully.');
                setActionCount(actionCount + 1);
                resetForm();
               //resetForm();
            } else if (response.status === 409) {
                toast.error('Employee already exists.');
            } else {
                toast.error('Error saving employee.', response.statusText);
            }
        } catch (error) {
            console.error('API Call Error:', error);
            toast.error('Error saving Employee: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };


    
console.log('departments',departments)

    return (
        <div className="">
            <div className="flex">
                <h2 className="text-blue-700 mb-3 text-1xl font-bold text-center border-b-2 border-blue-200 pb-2">New Employee</h2>
            </div> 
            <ToastContainer position="top-center" autoClose={2000} />
            <div className="max-w-xl mx-auto">
                {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-900 bg-opacity-50 z-50">
                        <Audio type="Bars" color="#FFF" height={50} width={50} />
                    </div>
                )}
                <div className="w-full">
                    <div className="shadow-md rounded px-8 pt-4 mb-2 bg-blue-25 p-4">
                        <div className="relative z-0 mb-8  group">
                            <input type="text"  id="title" className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer" 
                                placeholder=" "  
                                name="first_name"
                                onChange={(event) => handleTextChange(event)}
                                value={first_name}
                            />
                            <label htmlFor="first_name" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">First Name
                            </label>
                        </div>
              
                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="last_name"
                                className="block py-2.5 px-0 w-full text-sm
                                text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none
                                dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none 
                                focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={last_name}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="last_name"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Last Name
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="phone_number"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="parentsList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                key={employee?.id} // Assuming 'id' is a unique identifier
                                value={phone_number}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="phone_number"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Telephone
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="email"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={email}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="email"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Email
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="department_name"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                list="departmentList" // Link the input to the datalist
                                onChange={(event) => handleTextChange(event)}
                                value={department_name}
                            />
                            <datalist id="departmentList">{departments.map((department) => (<option value={department.name} />))}
                            </datalist> 
                            {/* Use the datalist element for autocomplete */}
                            <label htmlFor="department" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    Department
                            </label>
                        </div>

                        
                        <div className="relative z-0 mb-8 group">
                            <input
                                type="text"
                                name="job_title"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                                value={job_title}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label htmlFor="job_title" className="absolute  text-gray-500 dark:text-gray-400 duration-300 
                                transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
                                peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 
                                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Job Title
                            </label>
                        </div>

                        <div className="relative z-0 mb-8 group">
                            <input
                                type="date"
                                name="hire_date"
                                className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-gray-500 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                placeholder=" "
                                onChange={(event) => handleTextChange(event)}
                          
                                value={hire_date}
                            />
                            {/* Use the datalist element for autocomplete */}
                            <label
                                htmlFor="hire_date"
                                className="absolute text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                            >
                                Hired Date
                            </label>
                        </div>

                        <div className="relative z-0 mb-3 w-full group flex items-center">
                            <input
                                className="block py-2.5 px-0 w-full text-gray-900 bg-transparent dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-order-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-blue-600 peer"
                                type="file"
                                accept="image/*"
                                name='img'
                                onChange={(event) => handleImgChange(event)}
                            />
            
                            <label htmlFor="img" className="absolute  text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Profile picture</label>
                           
                            <img
                                className="rounded-full h-24 w-24"
                                src={`data:${imgType};base64,${displayImg}`}
                                alt=""
                            />
                         
                        </div>

                        <div className="text-center">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewEmployee;
