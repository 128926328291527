import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineCancel } from 'react-icons/md';
import { Audio } from 'react-loader-spinner';
import { IoMdAdd } from 'react-icons/io';
import { IoIosMore } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { Layout, Header, Modal, NewProduct, EditProduct, DeleteProduct } from '../../../../components';
import fetchData from '../../../../contexts/Api';
import { formatFriendlyDate,formatCurrency } from '../../../../utils/utils';
import AppContext from '../../../../contexts/AppContext';
import { ToastContainer } from 'react-toastify';

const Products = () => {
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [actionType, setActionType] = useState('');
    const { userData, currentColor, actionCount } = useContext(AppContext);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;

    useEffect(() => {
        setIsLoading(true);
        fetchProducts();
    }, [actionCount]);

    const fetchProducts = async () => {
        await fetchData('products', userData, setProducts);
        setIsLoading(false);
    };

    const filteredProducts = products.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        if (pageNumber <= totalPages && pageNumber > 0) {
            setCurrentPage(pageNumber);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset page number on search
    };

    const handleAction = (action, product) => {
        console.log('product sir ',product)
        setActionType(action);
        setProduct(product);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleClose = () => {
        navigate(-1);
    };

    const Pagination = ({ currentPage, totalPages, paginate }) => {
        const handlePageClick = (page) => {
            if (page >= 1 && page <= totalPages) {
                paginate(page);
            }
        };

        return (
            <div className="flex justify-center mt-2">
                <button
                    onClick={() => handlePageClick(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="mx-2 px-2  bg-gray-300 rounded hover:bg-gray-400 transition disabled:opacity-50"
                >
                    &lt;
                </button>

                {currentPage > 1 && (
                    <button
                        onClick={() => handlePageClick(currentPage - 1)}
                        className={`mx-1 px-2  rounded transition ${currentPage === currentPage - 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 hover:bg-gray-400'}`}
                    >
                        {currentPage - 1}
                    </button>
                )}

                <span className="mx-2  font-bold text-gray-700">{currentPage}</span>

                {currentPage < totalPages && (
                    <button
                        onClick={() => handlePageClick(currentPage + 1)}
                        className={`mx-1 px-2  rounded transition ${currentPage === currentPage + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 hover:bg-gray-400'}`}
                    >
                        {currentPage + 1}
                    </button>
                )}

                <button
                    onClick={() => handlePageClick(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="mx-2 px-2  bg-gray-300 rounded hover:bg-gray-400 transition disabled:opacity-50"
                >
                    &gt;
                </button>
            </div>
        );
    };
    console.log('currentItems',currentItems)
    return (
        <Layout>
            <div className="m-1 md:m-2 mt-1 p-2 md:p-2 rounded-3xl bg-white shadow-md">
                <ToastContainer position="top-center" autoClose={2000} />
                <div className="mt-1 justify-center text-center">
                    <div className="w-full sm:w-auto sm:flex-1 sm:px-4 py-2 flex flex-row justify-end">
                        <button
                          className="bg-transparent hover:py-1 px-1 rounded mr-2 transition-all duration-300"
                          title="Close"
                          onClick={handleClose}
                        >
                          <MdOutlineCancel size={24} color={currentColor} />
                        </button>
                    </div>
                    <h2 className='text-3xl mb-2 font-bold'>PRODUCTS</h2>
                    {/* <Header category="" title="Products" /> */}
                </div>
  
                <Modal open={isModalOpen} onClose={handleCloseModal} minWidth="90%">
                    {isModalOpen && actionType === 'new' && <NewProduct onClose={handleCloseModal} />}
                    {isModalOpen && actionType === 'edit' && <EditProduct singleData={product} data={product} onClose={handleCloseModal} />}
                    {isModalOpen && actionType === 'delete' && <DeleteProduct rowData={product} onClose={handleCloseModal} />}
                </Modal>
  
                {isLoading ? (
                    <div className="flex items-center flex-col">
                      <Audio height="80" width="80" radius="9" color={currentColor} ariaLabel="three-dots-loading" />
                      <p className="mt-2">Loading data. Please wait...</p>
                    </div>
                ) : (
                    <>
                        <div className=" rounded-3xl max-w-4xl mx-auto">
                            <div className="max-w-lg flex items-center  mb-2 mx-auto"> {/* Changed justify-between to items-center */}
                                {/* Pagination */}

                                <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
                                
                                {/* Search Input */}
                                <div id="search" className="p-1 md:m-1 flex-grow  rounded-3xl">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        className="mt-1 mb-2 px-4 py-2 w-full rounded-2xl border border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 focus:ring-blue-300"
                                        style={{ minWidth: '300px' }} // Set minimum width for the input
                                    />
                                </div>
                                {/* New Quotation Button */}
                                <button
                                    className="ml-4 px-4 py-2 text-xs font-medium rounded-lg bg-white focus:outline-none flex items-center space-x-1"
                                    style={{ border: `1px solid ${currentColor}`, color: currentColor }}
                                    onClick={() => handleAction('new', {})}
                                >
                                    <IoMdAdd />
                                    <span className="ml-1">New Product</span>
                                </button>
                            </div>
{/* Product List */}
<div className="w-full bg-white rounded-lg ">
    <div className="grid grid-cols-6 gap-4 p-3 border-b bg-gray-100  text-slate-500">
        <div className="text-center"><span className="text-sm text-slate-400 whitespace-nowrap">#</span></div>
        <div className=""><span className="text-sm text-slate-400 whitespace-nowrap">Product</span></div> {/* Adjusted column span */}
        <div className=""><span className="text-sm text-slate-400 whitespace-nowrap">Measure</span></div>
        <div className="text-center"><span className="text-sm text-slate-400 whitespace-nowrap">Price</span></div>
        <div className="text-center col-span-1"><span className="text-sm text-slate-400 whitespace-nowrap">Qty in Stock</span></div>
        {/* <div className="text-right"><span className="text-sm text-slate-400 whitespace-nowrap"></span></div> */}
    </div>

    {currentItems.length > 0 ? (
        currentItems.map((product, index) => (
            <div onClick={() => handleAction('edit', product)} key={product.id} className="grid grid-cols-6 gap-4 p-3 border-t cursor-pointer hover:bg-gray-200">
                <div className="text-center">
                    <span className="text-sm text-gray-900">{indexOfFirstItem + index + 1}</span> 
                   
                </div> 
                <div className="flex items-center" style={{ width: '160px' }}>
                    
                      <img className="rounded-full h-10 w-10" src={product?.photoUrl} alt="" />
                      <div className="ml-4 flex flex-col" style={{ width: '160px' }}>
                        <div className="leading-snug text-sm text-gray-900 font-bold">{product?.name}</div>
                        <div className="leading-snug text-xs text-gray-600">{product?.product_categories?.name}</div>
                      </div>
                    </div>

                <div className="text-sm"><span className="text-gray-900">{product?.prices?.sell_uom_name || 'N/A'}</span></div>
                <div className="text-right  text-sm">
                    <span className="text-gray-900">
                        {product?.prices?.sell_price_new ? formatCurrency(product.prices.sell_price_new) : 'N/A'}
                    </span>
                </div>
                <div className="text-center col-span-1 text-sm">
                    <span className={`text-sm text-white py-1 px-2 rounded-full font-semibold 
                        ${product?.quantity_instock <= 0 
                            ? 'bg-red-500' // Quantity is 0 or less
                            : product?.quantity_instock < product?.reorder_quantity
                            ? 'bg-yellow-500' // Quantity is greater than 0 but less than reorder quantity
                            : 'bg-green-500' // Quantity is greater than or equal to reorder quantity
                        }`}
                    >
                        {product?.quantity_instock}
                    </span>
                </div>

                {/* <div className="text-right">
                    <button
                        className="h-6 px-2 text-sm rounded-full hover:bg-blue-100 whitespace-nowrap"
                        onClick={() => handleAction('edit', product)}
                        title="Detail"
                    >
                        <IoIosMore size={20} color={currentColor} />
                    </button>
                </div> */}
            </div>
        ))
    ) : (
        <p className="p-3 text-center">No Product available.</p>
    )}
</div>

  
                          {/* Pagination */}
                          <Pagination currentPage={currentPage} totalPages={totalPages} paginate={paginate} />
                      </div>
                  </>
              )}
          </div>
      </Layout>
  );
  
};

export default Products;
